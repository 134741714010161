import React from 'react'
import { makeStyles } from '@mui/styles'

import { palette, themeMultiply, various } from '../../utils/theme'

const useStyles = makeStyles({
    input: {
        display: 'none',
        '&:checked + label': {
            borderColor: palette.primary.main,
        },
        '&:hover:not(:checked) + label': {
            borderColor: palette.primary.light,
        },
        '&:checked + label + div': {
            display: 'flex',
        },
    },

    label: {
        cursor: 'pointer',
        order: 1,
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto',
        padding: various.padding,
        paddingBottom: '5px',
        fontSize: '1.4em',
        fontWeight: 'bold',
        textAlign: 'center',
        borderBottom: '2px solid transparent',
    },

    content: {
        order: 2,
        display: 'none',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        padding: themeMultiply(various.padding, 3),
    },
})

export const Tab = ({ children, name, checked, onClick }) => {
    const classes = useStyles()

    return (
        <>
            <input
                id={name}
                className={classes.input}
                type='radio'
                checked={checked}
                onClick={onClick}
                readOnly
            />

            <label
                className={classes.label}
                htmlFor={name}
            >
                {name}
            </label>

            <div
                id={name}
                className={classes.content}
            >
                {children}
            </div>
        </>
    )
}
