import { useTranslation } from 'react-i18next'
import { Button, Modal, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useRecoilState } from 'recoil'

import { Card } from 'ui'

import { useRouter } from 'hooks/router'
import { isAskingAddCreditCardAtom } from 'recoil/atoms'
import { urls } from 'utils/constants'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    title: {
        color: theme.palette.secondary.main,
        marginBottom: '2vh',
    },

    button: {
        marginTop: '5vh',
    },
}))

export const AskCreditCard = () => {
    const classes = useStyles()
    const router = useRouter()
    const { t } = useTranslation()

    const [isAskinAddCreditCard, setIsAskingAddCreditCard] = useRecoilState(
        isAskingAddCreditCardAtom,
    )

    return (
        <Modal
            className={classes.modal}
            open={isAskinAddCreditCard}
            onClose={() => {
                setIsAskingAddCreditCard(false)
            }}
        >
            <Card>
                {/* Title */}
                <Typography
                    className={classes.title}
                    variant='h1'
                >
                    {t('creditCard')}
                </Typography>

                {/* Message */}
                <div>{t('needsCreditCard')}</div>

                {/* Add button */}
                <Button
                    className={classes.button}
                    variant='contained'
                    onClick={() => {
                        setIsAskingAddCreditCard(false)
                        router.push(urls.ACCOUNT)
                    }}
                >
                    {t('add')}
                </Button>
            </Card>
        </Modal>
    )
}
