import { useHistory } from 'react-router-use-history'
import { useRecoilValue } from 'recoil'
import { clientNameAtom } from 'recoil/atoms'

export const useRouter = () => {
    const history = useHistory()
    const clientName = useRecoilValue(clientNameAtom)

    const push = (url) => {
        if (!clientName) return history.push(url)

        history.push(`/${clientName}${url}`)
    }

    return {
        push,
    }
}
