import { makeStyles } from '@mui/styles'

import { joinStyles, various } from 'utils/theme'

const useStyles = makeStyles({
    root: {
        '&> tr > th:first-child': {
            borderTopLeftRadius: various.borderRadius,
        },

        '&> tr > th:last-child': {
            borderTopRightRadius: various.borderRadius,
        },
    },
})

export const TableHeader = ({ className, ...properties }) => {
    const classes = useStyles()

    return (
        <thead
            className={joinStyles(classes.root, className)}
            {...properties}
        />
    )
}
