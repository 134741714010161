import { makeStyles } from '@mui/styles'

import { joinStyles } from 'utils/theme'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
})

export const Form = ({ className, ...properties }) => {
    const classes = useStyles()

    return (
        <form
            className={joinStyles(classes.root, className)}
            {...properties}
        />
    )
}
