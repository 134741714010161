export const PAGINATION_LIMIT = 20

// Cookies
const COOKIE_EXPIRES = 365 * 2 // 2 years
const COOKIE_OPTIONS = {
    expires: COOKIE_EXPIRES,
    path: '/',
    sameSite: 'strict',
}

export const cookies = {
    ACCESS_TOKEN: 'access_token',
    ID: 'id',
    OPTIONS: COOKIE_OPTIONS,
    REFRESH_TOKEN: 'refresh_token',
}

export const links = {
    E_THIK: 'https://www.e-thik.com',
}

/** Make a pause with the time given (in milliseconds). */
export const pause = async (time) => {
    await new Promise((resolve) => setTimeout(resolve, time))
}

export const DRAWER_WIDTH = 260
export const GRID_SPACING = 3
