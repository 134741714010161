import { makeStyles, useTheme } from '@mui/styles'

import { joinStyles, themeMultiply, various } from 'utils/theme'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: various.margin,
        padding: themeMultiply(various.padding, 2),
        borderRadius: various.borderRadius,
        backgroundColor: theme.palette.background.default,
        boxShadow: various.boxShadow.component,
    },
}))

export const Card = ({ className, column, ...properties }) => {
    const theme = useTheme()
    const classes = useStyles(theme)

    return (
        <div
            className={joinStyles(classes.root, className)}
            {...properties}
        />
    )
}
