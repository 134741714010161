import { useEffect } from 'react'

import { useUserStore } from 'stores/user'

export const InitStores = ({ setTheme }) => {
    const { initialiseUser } = useUserStore()

    useEffect(() => {
        const initialisation = async () => {
            await initialiseUser(setTheme)
        }

        initialisation()
    }, [])

    return <></>
}
