import { makeStyles } from '@mui/styles'

import { joinStyles, various } from 'utils/theme'

const useStyles = makeStyles({
    root: {
        padding: various.padding,
        textAlign: 'center',
        borderBottom: '1px solid grey',
    },

    content: {
        display: 'flex',
        justifyContent: 'center',
    },
})

export const TableCell = ({ children, className, ...properties }) => {
    const classes = useStyles()

    return (
        <td
            className={classes.root}
            {...properties}
        >
            <div className={joinStyles(classes.content, className)}>
                {children}
            </div>
        </td>
    )
}
