import ky from 'ky'
import { getCookie } from 'typescript-cookie'

import { useUserStore } from './user'
import { cookies } from 'utils/constants'

export const reqKy = ky.create({
    cache: 'no-cache',
    prefixUrl: process.env.REACT_APP_API_ADDRESS,
    retry: 2,
})

export const useApiStore = () => {
    const { updateTokens } = useUserStore()

    const req = reqKy.extend({
        hooks: {
            beforeRequest: [
                async (request) => {
                    await updateTokens()

                    const accessToken = getCookie(cookies.ACCESS_TOKEN)

                    request.headers.set('Authorization', `JWT ${accessToken}`)
                },
            ],
        },
    })

    return {
        req,
    }
}
