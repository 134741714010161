import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Menu, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Language } from '@mui/icons-material'
import oldCookies from 'js-cookie'

import { Flex } from 'ui'

import { langList } from 'utils/app/lang'
import { assets } from 'utils/constants'
import { various } from 'utils/theme'

const useStyles = makeStyles((theme) => ({
    setting: {
        height: '45px',
        margin: various.padding,
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        borderRadius: various.borderRadius,
    },

    langageIcon: {
        height: '35px',
    },

    flagBox: {
        width: '50px',
        paddingRight: '5px',
    },

    flag: {
        height: '20px',
        maxWidth: '100%',
    },
}))

export const ChooseLangage = ({ setWantsNavigate }) => {
    const classes = useStyles()
    const { i18n } = useTranslation()

    const [anchorEl, setAnchorEl] = useState(null)

    const isOpen = Boolean(anchorEl)
    const langages = langList()

    /** Open the langage selection. */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    /** Close the langage selection. */
    const handleClose = () => {
        if (setWantsNavigate) setWantsNavigate(false)

        setAnchorEl(null)
    }

    /** Change the langage for the new selected. */
    const handleChangeLangage = (locale) => {
        i18n.changeLanguage(locale)
        oldCookies.set('i18next', locale)
    }

    return (
        <>
            <Button
                className={classes.setting}
                variant='outlined'
                onClick={handleClick}
            >
                <Language className={classes.langageIcon} />
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
            >
                {langages.map((langage) => (
                    <MenuItem
                        onClick={() => {
                            handleClose()
                            handleChangeLangage(langage.locale)
                        }}
                    >
                        <Flex className={classes.flagBox}>
                            <img
                                className={classes.flag}
                                src={assets.flags[langage.locale]}
                                alt={langage.name}
                            />
                        </Flex>

                        {langage.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
