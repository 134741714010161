import { makeStyles } from '@mui/styles'

import { joinStyles } from 'utils/theme'

const useStyles = makeStyles({
    root: {
        overflow: 'auto',
    },

    table: {
        width: '100%',
        borderSpacing: '0',
    },
})

export const Table = ({ className, ...properties }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <table
                className={joinStyles(classes.table, className)}
                {...properties}
            />
        </div>
    )
}
