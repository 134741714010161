import { makeStyles } from '@mui/styles'

import { joinStyles } from 'utils/theme'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    column: {
        flexDirection: 'column',
    },
})

export const Flex = ({ className, column, ...properties }) => {
    const classes = useStyles()

    return (
        <div
            className={joinStyles(
                classes.root,
                column && classes.column,
                className,
            )}
            {...properties}
        />
    )
}
