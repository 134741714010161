import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const PRIMARY_LIGHT = 'hsl(240, 40%, 30%)'
const PRIMARY_MAIN = 'hsl(240, 40%, 20%)'

const SECONDARY_MAIN = 'hsl(160, 75%, 65%)'

export const defaultTheme = responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                light: PRIMARY_LIGHT,
                main: PRIMARY_MAIN,
                dark: PRIMARY_LIGHT,
            },
            secondary: {
                main: 'hsl(160, 75%, 78%)',
                dark: SECONDARY_MAIN,
            },
            background: {
                default: 'hsl(0, 0%, 95%)',
            },
            text: {
                primary: 'hsl(0, 0%, 20%)',
                secondary: 'hsl(0, 0%, 50%)',
            },
        },

        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        width: 'unset',
                        fontWeight: 500,
                        borderRadius: '4px',
                    },
                },
            },
            MuiPaper: {
                defaultProps: {
                    elevation: 0,
                },
                styleOverrides: {
                    root: {
                        backgroundImage: 'none',
                    },
                    rounded: {
                        borderRadius: `10px`,
                    },
                },
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        color: PRIMARY_MAIN,
                        padding: '24px',
                    },
                    title: {
                        fontSize: '1.125rem',
                    },
                },
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        padding: '24px',
                    },
                },
            },
            MuiCardActions: {
                styleOverrides: {
                    root: {
                        padding: '24px',
                    },
                },
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        color: SECONDARY_MAIN,
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        '&.Mui-selected': {
                            color: '#1e1e48',
                            backgroundColor: 'white',
                            '&:hover': {
                                color: '#1e1e48',
                                backgroundColor: 'white',
                            },
                            '& .MuiListItemIcon-root': {
                                color: '#1e1e48',
                            },
                        },
                        '&:hover': {
                            backgroundColor: 'white',
                            color: '#1e1e48',
                            '& .MuiListItemIcon-root': {
                                color: '#1e1e48',
                            },
                        },
                    },
                },
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        color: PRIMARY_MAIN,
                        minWidth: '36px',
                    },
                },
            },
            MuiListItemText: {
                '&:hover': {
                    color: '#1e1e48',
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        color: PRIMARY_MAIN,
                        '&::placeholder': {
                            color: SECONDARY_MAIN,
                            fontSize: '0.875rem',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        background: 'white',
                        borderRadius: `10px`,
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#e0e0e0',
                        },
                        '&:hover $notchedOutline': {
                            borderColor: PRIMARY_MAIN,
                        },
                        '&.MuiInputBase-multiline': {
                            padding: 1,
                        },
                    },
                    input: {
                        fontWeight: 500,
                        background: 'white',
                        padding: '15.5px 14px',
                        borderRadius: `10px`,
                        '&.MuiInputBase-inputSizeSmall': {
                            padding: '10px 14px',
                            '&.MuiInputBase-inputAdornedStart': {
                                paddingLeft: 0,
                            },
                        },
                    },
                    inputAdornedStart: {
                        paddingLeft: 4,
                    },
                    notchedOutline: {
                        borderRadius: `10px`,
                    },
                },
            },
            MuiSlider: {
                styleOverrides: {
                    root: {
                        '&.Mui-disabled': {
                            color: 'white',
                        },
                    },
                    mark: {
                        backgroundColor: 'white',
                        width: '4px',
                    },
                    valueLabel: {
                        color: PRIMARY_MAIN,
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        borderColor: '#eeeeee',
                        opacity: 1,
                    },
                },
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        color: SECONDARY_MAIN,
                        background: 'white',
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        '&.MuiChip-deletable .MuiChip-deleteIcon': {
                            color: 'inherit',
                        },
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        color: '#ffff',
                        background: '#616161',
                    },
                },
            },
        },
        typography: {
            h7: {
                fontSize: '0.875rem',
                color: '#1e1e48',
                fontWeight: 500,
            },
            h6: {
                fontWeight: 500,
                color: '#212121',
                fontSize: '0.75rem',
            },
            h5: {
                fontSize: '0.875rem',
                color: '#212121',
                fontWeight: 500,
            },
            h4: {
                fontSize: '1rem',
                color: '#212121',
                fontWeight: 600,
            },
            h3: {
                fontSize: '1.25rem',
                color: '#212121',
                fontWeight: 600,
            },
            h2: {
                fontSize: '1.5rem',
                color: '#212121',
                fontWeight: 700,
            },
            h1: {
                fontSize: '2.125rem',
                color: '#212121',
                fontWeight: 700,
            },
            subtitle1: {
                fontSize: '0.875rem',
                fontWeight: 500,
                color: PRIMARY_MAIN,
            },
            subtitle2: {
                fontSize: '0.75rem',
                fontWeight: 400,
                color: SECONDARY_MAIN,
            },
            caption: {
                fontSize: '0.75rem',
                color: SECONDARY_MAIN,
                fontWeight: 400,
            },
            body1: {
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: '1.334em',
            },
            body2: {
                letterSpacing: '0em',
                fontWeight: 400,
                lineHeight: '1.5em',
                color: PRIMARY_MAIN,
            },
            button: {
                textTransform: 'unset',
            },
            customInput: {
                marginTop: 1,
                marginBottom: 1,
                '& > label': {
                    top: 23,
                    left: 0,
                    color: '#9e9e9e',
                    '&[data-shrink="false"]': {
                        top: 5,
                    },
                },
                '& > div > input': {
                    padding: '30.5px 14px 11.5px !important',
                },
                '& legend': {
                    display: 'none',
                },
                '& fieldset': {
                    top: 0,
                },
            },
            mainContent: {
                width: '100%',
                minHeight: 'calc(100vh - 86px)',
                flexGrow: 1,
                padding: '20px',
                marginTop: '86px',
                marginRight: '0px',
                borderRadius: `0px`,
            },
            menuCaption: {
                fontSize: '0.875rem',
                fontWeight: 500,
                color: '#212121',
                padding: '6px',
                marginTop: '10px',
                textTransform: 'unset',
            },
            subMenuCaption: {
                fontSize: '0.6875rem',
                fontWeight: 500,
                color: SECONDARY_MAIN,
                textTransform: 'unset',
            },
            commonAvatar: {
                cursor: 'pointer',
                borderRadius: '8px',
            },
            smallAvatar: {
                width: '22px',
                height: '22px',
                fontSize: '1rem',
            },
            mediumAvatar: {
                width: '34px',
                height: '34px',
                fontSize: '1.2rem',
            },
            largeAvatar: {
                width: '44px',
                height: '44px',
                fontSize: '1.5rem',
            },
        },
    }),
)
export const themeClient = ({ primary, secondary }) => {
    return responsiveFontSizes(
        createTheme({
            ...defaultTheme,
            palette: {
                primary: {
                    main: primary,
                },
                secondary: {
                    main: secondary,
                },
            },
        }),
    )
}
export default defaultTheme

const primaryRawMain = '240, 40%, 20%'
const secondaryRawMain = '160, 75%, 80%'

export const palette = {
    primaryRaw: {
        main: '240, 40%, 20%',
    },

    primary: {
        main: `hsl(${primaryRawMain})`,
    },

    secondaryRaw: {
        main: '160, 75%, 80%',
    },

    secondary: {
        main: `hsl(${secondaryRawMain})`,
    },

    text: {
        primary: 'hsl(0, 0%, 5%)',
        secondary: 'hsl(0, 0%, 95%)',
    },

    background: {
        primary: 'hsl(0, 0%, 95%)',
    },

    success: {
        main: 'hsl(120, 40%, 50%)',
        gradient:
            'linear-gradient(to bottom right, hsl(120, 40%, 45%), hsl(120, 40%, 60%))',
    },

    error: {
        main: 'hsl(5, 90%, 60%)',
        gradient:
            'linear-gradient(to bottom right, hsl(5, 90%, 55%), hsl(5, 90%, 65%))',
    },

    grey: {
        10: 'hsl(0, 0%, 10%)',
        20: 'hsl(0, 0%, 20%)',
        30: 'hsl(0, 0%, 30%)',
        40: 'hsl(0, 0%, 40%)',
        50: 'hsl(0, 0%, 50%)',
        60: 'hsl(0, 0%, 60%)',
        70: 'hsl(0, 0%, 70%)',
        80: 'hsl(0, 0%, 80%)',
        90: 'hsl(0, 0%, 90%)',
    },

    blue: {
        main: 'hsl(240, 50%, 30%)',
        gradient:
            'linear-gradient(to bottom right, hsl(240, 100%, 25%), hsl(240, 100%, 35%))',
    },

    orange: {
        main: 'hsl(30, 80%, 55%)',
        gradient:
            'linear-gradient(to bottom right, hsl(30, 80%, 45%), hsl(30, 90%, 65%))',
    },

    purple: {
        main: 'hsl(300, 100%, 30%)',
    },

    turquoise: {
        main: 'hsl(175, 75%, 55%)',
        gradient:
            'linear-gradient(to bottom right, hsl(175, 75%, 45%), hsl(175, 75%, 65%))',
    },
}

/** Various theme values. */
export const various = {
    borderRadius: '10px',
    margin: '20px',
    padding: '15px',
    boxShadow: {
        component: '2px 2px 5px hsla(0, 0%, 0%, 0.4)',
    },
}

/** Multiply a theme value (wait for Vanilla extract). */
export const themeMultiply = (value, multiplication) => {
    const digit = parseFloat(value)
    const unit = value.match(/[a-zA-Z]/g).join('')

    return `${digit * multiplication}${unit}`
}

/** Return the class name to use without falsy values. */
export const joinStyles = (...styles) => {
    return styles.filter(Boolean).join(' ')
}
