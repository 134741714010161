export const assets = {
    eThik: {
        LOGO: '/e-thik/logo',
    },

    flags: {
        'en-GB': '/flags/united_kingdom.svg',
        'es-ES': '/flags/spain.svg',
        'fr-FR': '/flags/france.svg',
    },
}
