import i18n from 'i18n'

/** Get an array of locale and its name sorted by name. */
export const langList = () => {
    const list = [
        {
            locale: 'en-GB',
            name: i18n?.t('english') ?? '',
        },
        {
            locale: 'es-ES',
            name: i18n?.t('spanish') ?? '',
        },
        {
            locale: 'fr-FR',
            name: i18n?.t('french') ?? '',
        },
    ]

    list.sort((previous, current) => previous.name.localeCompare(current.name))

    return list
}
