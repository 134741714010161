import { useTranslation } from 'react-i18next'
import { makeStyles, useTheme } from '@mui/styles'

import { joinStyles, various } from 'utils/theme'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },

    label: {
        cursor: 'pointer',
        margin: various.margin,
        padding: `10px 30px`,
        textAlign: 'center',
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main,
        borderRadius: various.borderRadius,
    },

    input: {
        display: 'none',
    },

    fileList: {
        display: 'flex',
        flexDirection: 'column',
    },
}))

export const UploadFile = ({
    className,
    files,
    setFiles,
    sizeLimit,
    ...properties
}) => {
    const theme = useTheme()
    const classes = useStyles(theme)
    const { t } = useTranslation()

    /** File verification on input file change. */
    const onChange = (event) => {
        const filesToAdd = event.target.files

        if (!sizeLimit) return setFiles([...filesToAdd])

        const filesFiltered = [...filesToAdd].filter(
            (file) => file.size <= sizeLimit,
        )

        setFiles(filesFiltered)
    }

    return (
        <div className={classes.root}>
            {/* Button */}
            <label className={joinStyles(classes.label, className)}>
                {t('chooseAFile')}

                <input
                    className={classes.input}
                    type='file'
                    onChange={onChange}
                    {...properties}
                />
            </label>

            {/* No file choosed */}
            {!files.length && <div>{t('noFileChosen')}</div>}

            {/* File list */}
            <div className={classes.fileList}>
                {files.map((file) => (
                    <div>{file.name}</div>
                ))}
            </div>
        </div>
    )
}
