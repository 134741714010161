import { makeStyles } from '@mui/styles'

import { joinStyles, palette } from 'utils/theme'

const useStyles = makeStyles({
    root: {
        '&> tr:hover': {
            backgroundColor: `hsla(${palette.primaryRaw.main}, 0.2)`,
        },
    },
})

export const TableBody = ({ className, ...properties }) => {
    const classes = useStyles()

    return (
        <tbody
            className={joinStyles(classes.root, className)}
            {...properties}
        />
    )
}
