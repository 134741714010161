import { makeStyles } from '@mui/styles'

import { joinStyles, palette, themeMultiply, various } from 'utils/theme'

const useStyles = makeStyles({
    root: {
        padding: themeMultiply(various.padding, 2),
        color: palette.secondary.main,
        fontSize: '1.2em',
        textAlign: 'center',
        backgroundColor: palette.primary.main,
    },
})

export const TableHeaderCell = ({ className, ...properties }) => {
    const classes = useStyles()

    return (
        <th
            className={joinStyles(classes.root, className)}
            {...properties}
        />
    )
}
