export const api = {
    CARDDETAILS: 'adyen_proxy/paymentMethods/',
    DASHBOARD: 'dashboard/',
    DELETECARD: 'adyen_proxy/storedPaymentMethods/',
    KONSOLE_COLORS: 'colors_parameters/',
    MACHINEGRIDITEM: 'machine_grid_item/',
    PAYMENTS: 'adyen_proxy/payments/',
    PURCHASES: 'sales/',
    REFILL: 'adyen_proxy/refill_payments/',
    REFILL_HISTORY: 'credit_refills/',
    REFILL_STATUS: 'credit_refill_status/',
    SESSIONS: 'adyen_proxy/sessions/',
    VALIDATE: 'validate-activation-token/',

    account: {
        konvives: {
            ADD_PRIVATE_CARD: 'account/konvives/add-private-card',
            DELETE_PRIVATE_CARD: 'account/konvives/delete-private-card',
        },
    },

    auth: {
        ASK_RESET_PASSWORD: 'auth/ask-reset-password',
        LOGIN: 'token/',
        REFRESH_TOKENS: 'token/refresh/',
        REGISTER: 'register/',
        RESET_PASSWORD: 'auth/reset-password',
    },

    user: {
        CHECK_CREDIT_CARD: 'user/check-credit-card',
        CONSUME: 'send_notification_to_machine/',
        PROFILE: 'me/',
        UPDATE_PASSWORD: 'password/change/',
    },
}
