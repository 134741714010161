export const urls = {
    ACCOUNT: '/account',
    DISPUTES: '/disputes',
    HOMEPAGE: '/',
    REFILL: '/refill',
    REFILLS: '/refills',
    STOCKS: '/stocks',

    auth: {
        ACTIVATION: '/activation',
        LOGIN: '/login',
        REGISTER: '/register',
        RESET_PASSWORD: '/auth/reset-password',
    },

    purchases: {
        DETAILS: '/purchases/:id',
    },
}
